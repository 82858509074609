body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.timeline-legend {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: 2px solid transparent;
    border-radius: 100px;
    display: inline-block;
    margin-right: 10px;
}

.timeline-legend-red {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.timeline-legend-green {
  color: #52c41a;
  border-color: #52c41a;
}

.timeline-legend-grey {
  color: grey;
    border-color: grey
}

.header-logo {
  float: left;
  width: 120px;
  margin-top: 20px;
}

.logo {
  width: 41px;
  height: 31px;
}

.textLogo {
  margin-top: 20px;
  width: 100px;
}

.site-layout-background {
  background: #fff;
}

.main-background {
  padding: 24px;
  margin: 0;
  min-height: 280;
}

.steps-content {
  margin-top: 16px;
  padding: 50px;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}